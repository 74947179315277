$fontSize: 13px;

.wd-250 {
	width: 250px !important;
}


.profile-image {
	width: 100px !important;
	height: 100px !important;
	padding: 2px;
	border-radius: 100px;
	border: solid 1px #cccccc;
	box-shadow: inset 0 0 0 4px rgba(221, 221, 221, 0.25);

	&:hover{
		padding: 4px;
		border: solid 2px #eeeeee;
		opacity: .5;
	}
}


.profile-content {
	width: 216px !important;
	color: #acacac;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: none;
	.text {
		margin-left: 4px;
		color: #666666;
		font-size: 12px;
	}
}

.last-divider {
	margin-bottom: 0px !important;
}

.logout{
	-webkit-transition: padding-left .35s;
	transition: padding-left .35s;
}	
	
.logout:hover{
	padding-left: 5px !important;
}

@media (max-width: 768px) {
	.d-md-none-force {
			display: none !important;
		}
	}