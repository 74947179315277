$fontSize: 13px;

.header-right-wrapper {
	display: flex !important;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	
    font-size: 1.1rem;
	display: inline-block;
	padding-top: .3125rem;
	padding-bottom: .3125rem;
	/* margin-right: 1rem; */
	color: #17a2b8;

	.dropdown {
		display: flex !important;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		.fullname-avatar {
			display: flex;
			.fullname {
				font-size: $fontSize;
				display: inline-block;
				max-width: 216px !important;
				margin-right: 4px;
				color: inherit !important;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				line-height: 30px;
			}
		}
	}

	.menu {
		/* styling the profile dropdown border and sub hearder text */
		border: 1px dashed rgba(38, 53, 74, .25) !important;
		box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16) !important;
		.sub {
			font-size: $fontSize !important;
			width: 216px;
			color: #666666 !important;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	
		.item:last-child {
			background-color: #EEEEEE;
			color: #666666 !important;
		}
		
		.header .item{
			background-color: inherit;
		}
	}
}
