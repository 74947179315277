.wrapper {
	text-align: left;
	background-color: #263549;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(9px + 1vmin);
	color: white;
}

.form-container {
	margin: 0 auto;
	width: 320px;
	padding: 20px 16px 20px 15px;
	background-color: #fafafa;
	border: 4px solid #cccccc;
	border-radius: 5px;
}

.login-logo {
	width: 110px !important;
	height: 110px !important;
	padding: 10px;
	border-radius: 100px;
	border: solid 1px #CCCCCC;
	box-shadow: inset 0 0 0 4px rgba(221, 221, 221, 0.25);
}

.error-container{
	display: block !important
}



