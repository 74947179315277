/*FOR CLICK ELEMENT RESIZING*/
.sidebar-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  background: transparent;
  letter-spacing: 0.2px;
  color: #adb5bd;
  font-size: 14px;
  position: fixed;
  z-index: 1000;

  .sidebar-module {
    background-attachment: fixed;
    background: url("../../../../images/map-bg.png") 50% 0 repeat-y fixed;
    background-size: cover;
    background-position: center center;
    background-color: #1d2939;
    scrollbar-color: #485668 #1d2939;
    scrollbar-width: thin;

    .sidebar-module-nav {
      /*ICON MODULES*/
      width: 52px;
      padding: 0;
      list-style: none;

      li {
        text-indent: 6px;
        line-height: 40px;
        margin: 10px 0px 10px 0px;

        a {
          padding-left: 2px;
          display: block;
          text-decoration: none;
          color: #cccccc;
          font-size: 15px;
          transition: all 0.1s;
          -webkit-transition: all 0.1s;
        }

        a:hover,
        a:focus {
          background-color: #1caf9a;
          border-left: 4px solid #1caf9a;
          color: #ffffff;
          background-image: linear-gradient(to right, #1caf9a 0%, #17a2b8 100%);
        }
      }
    }
  }
  /* .sidebar-module:hover {
		overflow-y: auto;
		scrollbar-color: #485668 #1d2939;
		scrollbar-width: thin;
	} */

  .sidebar-module-content {
    /* width: 220px; */
    background-attachment: fixed;
    background: url("../../../../images/map-bg.png") 50% 0 repeat-y fixed;
    background-size: cover;
    background-position: center center;
    background-color: #26354a;
    overflow: hidden;
    opacity: 0.92;
    box-shadow: 10px 0 5px -8.8px #888;
    transition: all 0.2s ease-in-out;
  }
}

.sidebar-module-active {
  background-color: #1caf9a;
  border-left: 4px solid #9dd6cb !important;
  color: #ffffff !important;
  background-image: linear-gradient(to right, #1caf9a 20%, #17a2b8 100%);
}

@media screen and (max-width: 768px) {
  .in-moble-view {
    display: none;
    flex-direction: row;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.sidebar-module-content-header {
  display: flex;
  flex-direction: row;
  background-color: #485668;
  font-size: 12px !important;
  padding: 2px 2px 2px 4px;
  align-items: center;
  justify-content: center;

  .sidebar-module-content-header-title {
    flex: 1;
    height: 22px;
    margin: 0px;
    padding: 2px 2px 2px 4px;
    opacity: 0.8;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    color: #cccccc;
    font-weight: 600;
  }

  .sidebar-module-content-header-close {
    padding: 2px !important;
    background-color: #138496;
    border-color: #117a8b;
    color: #cccccc;
  }
}

.sidebar-module-content-body {
  padding: 4px 4px 4px 4px;

  li {
    width: 250px;
    display: block;
    padding: 8px;

    a {
      color: #9da5ab;
      padding-left: 5px;
      font-family: Poppins, lucida grande, tahoma, verdana, arial, sans-serif;
      font-weight: 400;
      font-size: 0.9rem;
    }

    a:hover,
    a:focus {
      color: #ffffff;
    }
  }
}

.sidebar-submodule-close {
  width: 0px !important;
}
