.right-pane-tabs-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	font-size: .9rem;
	height: 2.5em;
	margin: 0;
	margin-top: 5px;
	margin-bottom: 2px;
	width: 100%;
	border-bottom: 1px solid rgba(38, 53, 74, 0.25) !important;
	box-shadow: 0 1px 4px 0px rgba(170, 181, 191, 0.1) !important;
	overflow: hidden;
	overflow-x: auto;
	white-space: nowrap;
	scrollbar-color: #cccccc #e9ecef;
	scrollbar-width: thin;
	background: #eaeaea;

	.tabs {
		border-bottom-color: transparent;
		border-bottom-style: solid;
		align-self: flex-end;
		margin: 0 4px -1px 4px;
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		vertical-align: middle;
		line-height: 1;
		justify-content: space-between;
		-webkit-tap-highlight-color: transparent;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		padding: .4em .4em;
		transition: background .1s ease, box-shadow .1s ease, color .1s ease;

		a {
			color: rgba(68, 68, 68, 0.87);
			font-weight: 600;
			text-align: center;
			margin-right: 4px;
		}

		a:hover {
			opacity: .6;
		}
	}

	.active-tab {
		border-bottom: 4px solid #17a2b8;
		font-weight: 700;
		a {
			color: #17a2b8;
		}
	}

	.close-tab {
		margin-left: auto;
		margin-right: 0 !important;
		padding: 2px !important;
	}

	&::-webkit-scrollbar {
		display: none;
	  }
}