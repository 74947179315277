.header-left-wrapper {
	font-weight: 600 !important;
	color: #212529;
	letter-spacing: 1px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: .3125rem;
	padding-bottom: .3125rem;
	margin-right: 1rem;
	margin-bottom: 0px !important;

	span {
		margin-left: .4em;
		font-size: 1.25rem;
	}

	em {
		font-style: normal;
		font-weight: 600;
		color: #17a2b8;
	}
}

.mobile-menu {
	margin-right: .4em !important;
	padding: 2px !important;
	display: none !important;
}

@media screen and (max-width: 768px) {
	.mobile-menu {
		display: inline !important;
		color: #17a2b8 !important;
	}
	.header-left-wrapper img {
		display: none !important;
	}
}
